
import { computed, defineComponent, PropType, SetupContext } from "vue";

import JsonTreeViewItem, {
  ItemType,
  ValueTypes,
  ItemData,
  SelectedElement,
} from "./JsonTreeViewItem.vue";

type Props = {
  data: string;
  rootKey: string;
  maxDepth: number;
  colorScheme: string;
  objectMap: Map<any, any>;
  tab: number;
  path: string;
  element: SelectedElement;
  clickAbleDataType: "normal" | "branched"
};

export default defineComponent({
  name: "JsonTreeView",
  components: { JsonTreeViewItem },
  props: {
    data: {
      type: String,
      required: false,
    },
    rootKey: {
      type: String,
      required: false,
      default: "/",
    },
    maxDepth: {
      type: Number,
      required: false,
      default: 1,
    },
    colorScheme: {
      type: String,
      required: false,
      default: "light",
      validator: (value: string) => ["light", "dark"].indexOf(value) !== -1,
    },
    objectMap: {
      type: Map,
      required: false,
      default: new Map(),
    },
    tab: {
      type: Number,
      required: false,
      default: 1,
    },
    path: {
      type: String,
      required: false,
      default: "path",
    },
    element: {
      type: Object as PropType<SelectedElement>,
      required: false,
      default: {
        key: "key",
        value: "value",
      },
    },
    clickAbleDataType: {
      type: String,
      default: "normal"
    }
  },
  setup(props: Props, context: SetupContext) {
    function itemSelected(data: any): void {
      context.emit("selected", data);
    }

    function build(
        key: string,
        value: ValueTypes,
        depth: number,
        path: string,
        includeKey: boolean,
        parent: ItemData | null = null
    ): ItemData {
      const children: ItemData[] = [];
      const currentParentType = value instanceof Array ? ItemType.ARRAY : ItemType.OBJECT;
      if (value instanceof Object) {
        const currentParent = {
          key,
          type: currentParentType,
          depth,
          path,
          length: 0,
          children: [],
          parent
        };
        if (value instanceof Array) {
          value.forEach((element, index) => {
            let layer = `${currentParent.path}@#${index}`;
            const childPath = includeKey ? `${removeAtFromPath(path)}${key}[${index}]` : layer;
            const child = build(
                index.toString(),
                element,
                depth + 1,
                childPath,
                false,
                currentParent
            );
            children.push(child);
          });
          return {
            key,
            type: ItemType.ARRAY,
            depth,
            path,
            length: children.length,
            children,
            parent,
          };
        }

        Object.entries(value).forEach(([childKey, childValue]) => {
          const childPath = includeKey ? `${removeAtFromPath(path)}${key}@#` : `${removeAtFromPath(path)}@#`;
          const child = build(
              childKey,
              childValue,
              depth + 1,
              childPath,
              true,
              currentParent
          );
          children.push(child);
        });
        children.forEach(child => {
          child.parent = currentParent;
        });
        return {
          key,
          type: ItemType.OBJECT,
          depth,
          path,
          length: children.length,
          children,
          parent
        };
      } else {
        const valuePath = includeKey ? `${removeAtFromPath(path)}${key}` : removeAtFromPath(path);
        return {
          key,
          type: ItemType.VALUE,
          path: valuePath,
          depth,
          value,
          parent
        };
      }
    }

    const parsed = computed(
        (): ItemData => {
          const json = props.data;
          if (json !== null && json !== undefined) {
            const data = JSON.parse(json);
            if (data instanceof Object) {
              return build(props.rootKey, { ...data }, 0, "", true);
            }
          }
          return {
            key: props.rootKey,
            type: ItemType.VALUE,
            path: "",
            depth: 0,
            value: props.data,
          };
        }
    );

    const map = computed(() => {
      return props.objectMap;
    });

    function removeSuffix(inputString: string) {
      const index = inputString.indexOf('@');
      if (index !== -1) {
        return inputString.slice(0, index);
      } else {
        return inputString;
      }
    }

    function removeAtFromPath(path: string) {
      if (path.length > 1 && path.charAt(1) === "@") {
        return path.slice(0, 1) + path.slice(2);
      }
      return path;
    }

    return {
      itemSelected,
      parsed,
      map,
    };
  },
});
