import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JsonTreeViewItem = _resolveComponent("JsonTreeViewItem")!

  return (_openBlock(), _createBlock(_component_JsonTreeViewItem, {
    class: _normalizeClass([{ 'root-item': true, dark: _ctx.colorScheme === 'dark' }]),
    data: _ctx.parsed,
    maxDepth: _ctx.maxDepth,
    objectMap: _ctx.map,
    tab: _ctx.tab,
    path: _ctx.path,
    element: _ctx.element,
    onSelected: _ctx.itemSelected,
    clickAbleDataType: _ctx.clickAbleDataType
  }, null, 8, ["class", "data", "maxDepth", "objectMap", "tab", "path", "element", "onSelected", "clickAbleDataType"]))
}